import React from "react";

import "./Form.css";

import imgSrc from "../Images/Form/tableImg.png";

export default function Form() {
  return (
    <div id="form" class="container-fluid">
      <div class="row align-items-start">
        <div class="column">
          <div id="formInfo" class="card">
            <div class="card-body">
              <div id="SV-AD-220a.1.">
                <h5 id="boldText" class="card-title">Data Privacy</h5>
                <h5 id="boldText" class="card-title">Accounting Metrics</h5>
                <h5 id="boldText" class="card-title">SV-AD-220a.1. Discussion of policies and practices relating to behavioral advertising and consumer privacy</h5>
                <p id="text">
                  1 The entity shall describe the nature, scope, and implementation of its policies and practices related to the privacy of
                  consumer information, with a specific focus on how the entity addresses the collection, usage, and retention of
                  consumers’ personally identifiable information (PII) and its approach to behavioral advertising.
                </p>
                <p id="text" className="indent">
                  1.1 Consumer information includes information that pertains to a user’s attributes or actions, including, but not
                  limited to, records of communications, content of communications, demographic data, behavioral data,
                  location data, or PII.
                </p>
                <p id="text" className="indent">
                  1.2 Demographic data are defined as the quantifiable statistics that identify and distinguish a given population.
                  Examples of demographic data include gender, age, ethnicity, knowledge of languages, disabilities, mobility,
                  home ownership, and employment status.
                </p>
                <p id="text" className="indent">
                  1.3 Behavioral data are defined as the product of tracking, measuring, and recording individual behaviors such as
                  consumers’ online browsing patterns, buying habits, brand preferences, and product usage patterns, among
                  others.
                </p>
                <p id="text" className="indent">
                  1.4 Behavioral advertising makes use of data that were the product of tracking, measuring, and recording
                  individual behaviors such as consumers’ online browsing patterns, buying habits, brand preferences, and
                  product usage patterns, among others.
                </p>
                <p id="text" className="indent">
                  1.5 Location data are defined as data describing the physical location or movement patterns of an individual, such
                  as Global Positioning System coordinates or other related data that would enable identifying and tracking of
                  an individual’s physical location.
                </p>
                <p id="text" className="indent">
                  1.6 PII is defined as any information about an individual that is maintained by an entity, including (1) any
                  information that can be used to distinguish or trace an individual’s identity, such as name, Social Security
                  Number (SSN), date and place of birth, mother’s maiden name, or biometric records; and (2) any other
                  information that is linked or linkable to an individual, such as medical, educational, financial, and employment
                  information. This definition is derived from the U.S. Government Accountability Office’s Report to
                  Congressional Requesters, Alternatives Exist for Enhancing Protection of Personally Identifiable Information.
                </p>
                <p id="text" className="indent">
                  1.7 The scope of disclosure includes information from first-, second-, and third-party sources.
                </p>
                <p id="text">
                  2 The entity shall describe the information “lifecycle” (i.e., collection, use, retention, processing, disclosure, and
                  destruction) and how information-handling practices at each stage may affect individuals’ privacy.
                </p>
                <p id="text" className="indent">
                  2.1 With respect to data collection, the entity may discuss which data or types of data are collected without the
                  consent of an individual, which require opt-in consent, and which require opt-out action from the individual.
                </p>
                <p id="text" className="indent">
                  2.2 With respect to data usage, the entity may discuss which data or types of data are used by the entity internally
                  and under what circumstances the entity shares, sells, rents, or otherwise distributes data or information to
                  third parties.
                </p>
                <p id="text" className="indent">
                  2.3 With respect to data retention, the entity may discuss which data or types of data it retains, the length of time
                  of retention, and practices used to ensure that data are stored securely.
                </p>
                <p id="text" className="indent">
                  3 The entity shall describe the degree to which its policies and practices address similar issues as those outlined in the
                  OMB Guidance for Implementing the Privacy Provisions of the E-Government Act of 2002 (M-03-22), including use
                  of Privacy Impact Assessments (PIAs).
                </p>
                <p id="text" className="indent">
                  3.1 A PIA is an analysis of how information is handled that ensures handling conforms to applicable legal,
                  regulatory, and policy requirements regarding privacy; determines the risks and effects of collecting,
                  maintaining, and disseminating information in identifiable form in an electronic information system; and
                  examines and evaluates protections and alternative processes for handling information in order to mitigate
                  potential privacy risks.
                </p>
                <p id="text" className="indent">
                  3.2 As outlined by OMB M-03-22, PIAs must analyze and describe: (a) what information is to be collected, (b) why
                  the information is being collected, (c) the intended use of the information, (d) with whom the information will
                  be shared, (e) what opportunities individuals have to decline to provide information (i.e., where providing
                  information is voluntary) or to consent to particular uses of the information (other than required or authorized
                  uses) and how individuals can grant consent, and (f) how the information will be secured, among other
                  government-specific requirements.
                </p>
                <p id="text">
                  4 With respect to behavioral advertising, the entity may describe how it addresses the following principles, described
                  by the cross-industry Self-Regulatory Principles for Online Behavioral Advertising:
                </p>
                <p id="text" className="indent">
                  4.1 Education—participation in educational efforts for consumers about behavioral online advertising
                </p>
                <p id="text" className="indent">
                  4.2 Transparency—clearly disclosing information about data collection and data-use practices
                </p>
                <p id="text" className="indent">
                  4.3 Consumer control—allowing users to choose whether data is collected or transferred to non-affiliates
                </p>
                <p id="text" className="indent">
                  4.4 Data security—providing basic security provisions and having clear policies relating to the retention of
                  consumer information
                </p>
                <p id="text" className="indent">
                  4.5 Material changes—obtaining consent before applying changes to policies that are less restrictive than existing
                  Ones
                </p>
                <p id="text" className="indent">
                  4.6 Sensitive data—abiding by the Children’s Online Privacy Protection Act (COPPA) and handling consumer data
                  such as financial information, Social Security numbers, and medical information
                </p>
                <p id="text" className="indent">
                  4.7 Accountability—participation in self-regulatory organizations such as the Direct Marketing Association
                </p>
              </div>
              <div id="SV-AD-220a.2.">
                <h5 id="boldText" class="card-title">Data Privacy</h5>
                <h5 id="boldText" class="card-title">Accounting Metrics</h5>
                <h5 id="boldText" class="card-title">SV-AD-220a.2. Percentage of online advertising impressions that are targeted to custom audiences</h5>
                <p id="text">
                  1 The entity shall disclose the percentage of its advertising impressions that are targeted to custom audiences.
                </p>
                <p id="text" className="indent">
                  1.1 Advertising impressions are defined as a measure of the number of opportunities that a viewer, listener, or
                  reader has to see or hear an online advertisement.
                </p>
                <p id="text" className="indent">
                  1.2 Custom audiences are defined as individuals to whom advertisers target their advertisements based on
                  consumer information pertaining to a user’s attributes or actions, including, but not limited to, records of
                  communications, content of communications, demographic data, behavioral data, location data, or personally
                  identifiable information (PII).
                </p>
                <p id="text" className="doubleIndent">
                  1.2.1 Demographic data are defined as the quantifiable statistics that identify and distinguish a given
                  population. Examples of demographic data include gender, age, ethnicity, knowledge of languages,
                  disabilities, mobility, home ownership, and employment status.
                </p>
                <p id="text" className="doubleIndent">
                  1.2.2 Behavioral data are defined as the product of tracking, measuring, and recording individual behaviors
                  such as consumers’ online browsing patterns, buying habits, brand preferences, and product usage
                  patterns, among others.
                </p>
                <p id="text" className="doubleIndent">
                  1.2.3 Location data are defined as data describing the physical location or movement patterns of an
                  individual, such as Global Positioning System coordinates or other related data that would enable
                  identifying and tracking of an individual’s physical location.
                </p>
                <p id="text" className="doubleIndent">
                  1.2.4 PII is defined as any information about an individual maintained by an entity, including (1) any
                  information that can be used to distinguish or trace an individual’s identity, such as name, Social
                  Security Number (SSN), date and place of birth, mother’s maiden name, or biometric records; and (2) any other information that is linked or linkable to an individual, such as medical, educational, financial,
                  and employment information. This definition is derived from the U.S. Government Accountability
                  Office’s Report to Congressional Requesters, Alternatives Exist for Enhancing Protection of Personally
                  Identifiable Information.
                </p>
                <p id="text" className="indent">
                  1.3 The entity shall calculate the percentage as the total number of advertising impressions that are targeted to
                  custom audiences, divided by the total number of advertising impressions.
                </p>
                <p id="text" className="doubleIndent">
                  1.3.1 The total number of advertising impressions shall be calculated in accordance with the Interactive
                  Advertising Bureau’s (IAB) Interactive Audience Measurement and Advertising Campaign Reporting and
                  Audit Guidelines as the sum of impressions that are targeted to custom audiences and impressions that
                  are not targeted to custom audiences, such as those targeted according to publisher (e.g., contextual advertising).
                </p>
                <p id="text">
                  2 The scope of disclosure is limited to impressions from advertising campaigns delivered online, including through
                  mobile devices or Internet-based TV services.
                </p>
                <p id="text" className="indent">
                  2.1 Advertising campaigns include (1) campaigns made directly by the entity and (2) campaigns the entity has
                  made on behalf of its customers, including those resulting from ad inventory sold on networks and exchanges
                  operated by the entity, ad space purchased on behalf of customers through platform buying services, and media plans developed by the entity that specify the locations and type of ad placement.
                </p>
              </div>
              <div id="SV-AD-270a.1.">
                <h5 id="boldText" class="card-title">Integrity</h5>
                <h5 id="boldText" class="card-title">Accounting Metrics</h5>
                <h5 id="boldText" class="card-title">SV-AD-270a.1. Total amount of monetary losses as a result of legal proceedings associated with false, deceptive, or unfair advertising</h5>
                <p id="text">
                  1 The entity shall disclose the total amount of monetary losses it incurred during the reporting period as a result of
                  legal proceedings associated with incidents relating to customer privacy.
                </p>
                <p id="text">
                  2 The legal proceedings shall include any adjudicative proceeding in which the entity was involved, whether before a
                  court, a regulator, an arbitrator, or otherwise
                </p>
                <p id="text">
                  3 The losses shall include all monetary liabilities to the opposing party or to others (whether as the result of settlement
                  or verdict after trial or otherwise), including fines and other monetary liabilities incurred during the reporting period
                  as a result of civil actions (e.g., civil judgments or settlements), regulatory proceedings (e.g., penalties, disgorgement,
                  or restitution), and criminal actions (e.g., criminal judgment, penalties, or restitution) brought by any entity (e.g.,
                  governmental, business, or individual).
                </p>
                <p id="text">
                  4 The scope of monetary losses shall exclude legal and other fees and expenses incurred by the entity in its defense.
                </p>
                <p id="text">
                  5 The scope of disclosure shall include, but is not limited to, legal proceedings associated with the enforcement of
                  relevant industry regulations, such as:
                </p>
                <p id="text" className="indent">
                  5.1 Directive 2002/58/EC (ePrivacy Directive)
                </p>
                <p id="text" className="indent">
                  5.2 U.S. Children’s Online Privacy Protection Act (COPPA)
                </p>
                <p id="text" className="indent">
                  5.3 U.S.-EU Safe Harbor Program
                </p>
                <p id="text" className="indent">
                  5.4 U.S. Federal Trade Commission (FTC) Privacy Act
                </p>
              </div>
              <div id="SV-AD-270a.2.">
                <h5 id="boldText" class="card-title">Integrity</h5>
                <h5 id="boldText" class="card-title">Accounting Metrics</h5>
                <h5 id="boldText" class="card-title">SV-AD-270a.2. Percentage of campaigns reviewed for adherence with the Advertising Self-Regulatory Council (ASRC) procedures, percentage of those in Compliance</h5>
                <p id="text">
                  1 The entity shall disclose the percentage of its advertising and marketing campaigns, by revenue, that were reviewed
                  by the Advertising Self-Regulatory Council (ASRC) for adherence to the ASRC voluntary self-regulatory procedures,
                  which are directed at performance of advertisers (e.g., the clients of advertising agencies).
                </p>
                <p id="text" className="indent">
                  1.1 ASRC procedures are defined as those associated with the following self-regulatory programs:
                </p>
                <p id="text" className="doubleIndent">
                  1.1.1 Children’s Advertising Review Unit (CARU)
                </p>
                <p id="text" className="doubleIndent">
                  1.1.2 Electronic Retailing Self-Regulation Program (ERSP)
                </p>
                <p id="text" className="doubleIndent">
                  1.1.3 National Advertising Division (NAD)
                </p>
                <p id="text" className="doubleIndent">
                  1.1.4 National Advertising Review Board
                </p>
                <p id="text" className="doubleIndent">
                  1.1.5 Online Interest-Based Advertising Accountability Program (Accountability Program)
                </p>
                <p id="text" className="indent">
                  1.2 The scope of disclosure is all advertising and marketing campaigns and associated recommendations by the
                  entity (e.g., placement of ads).
                </p>
                <p id="text" className="indent">
                  1.3 The percentage is calculated as the revenue associated with advertising and marketing campaigns that were reviewed by
                </p>
                <p id="text">
                  2 The entity shall disclose the percentage of its ASRC-reviewed advertising and marketing campaigns by revenue that
                  were found by ASRC to be in compliance with ASRC procedures.
                </p>
              </div>
              <div id="SV-AD-270a.3.">
                <h5 id="boldText" class="card-title">Integrity</h5>
                <h5 id="boldText" class="card-title">Accounting Metrics</h5>
                <h5 id="boldText" class="card-title">SV-AD-270a.3. Percentage of campaigns that promote alcohol or tobacco products</h5>
                <p id="text">
                  1 The entity shall disclose the percentage of its advertising and marketing campaigns that promote alcohol or tobacco products.
                </p>
                <p id="text" className="indent">
                  1.1 Tobacco products are defined by the U.S. Food and Drug Administration as any product made or derived from
                  tobacco that is intended for human consumption, including any component, part, or accessory of a tobacco
                  product. This includes, among other products, cigarettes, cigarette tobacco, roll-your-own tobacco, and
                  smokeless tobacco.
                </p>
                <p id="text" className="indent">
                  1.2 Alcohol products are any product capable of being consumed containing more than a half percent of ethanol or ethyl alcohol by volume.8
                </p>
                <p id="text">
                  2 The percentage is calculated as the total revenue generated from advertising and marketing campaigns that promote
                  alcohol or tobacco products, divided by the total revenue from all advertising and marketing campaigns.
                </p>
                <p id="text">
                  3 The entity may discuss its approach to managing potential impacts from specific rules, prohibitions, media
                  restrictions, labeling disclosures, and warning requirements applicable to advertising for alcohol or tobacco products
                  (e.g., bans on outdoor advertisements for tobacco products in the U.S. and EU).
                </p>
              </div>
              <div id="SV-AD-330a.1.">
                <h5 id="boldText" class="card-title">Workforce Diversity & Inclusion</h5>
                <h5 id="boldText" class="card-title">Accounting Metrics</h5>
                <h5 id="boldText" class="card-title">SV-AD-330a.1. Percentage of gender and racial/ethnic group representation for (1)
                  management, (2) professionals, and (3) all other employees</h5>
                <p id="text">
                  1 The entity shall disclose gender representation for all employees and racial/ethnic group representation for its U.S.
                  employees by employee category.
                </p>
                <p id="text" className="indent">
                  1.1 The following employee categories shall be used: (1) management, (2), professionals, and (3) all other employees.
                </p>
                <p id="text">
                  2 Gender and racial/ethnic group representation shall be disclosed in percentages, where the percentage shall be
                  calculated as the number of employees in each gender or racial/ethnic group in each employee category divided by
                  the total number of employees in the respective employee category.
                </p>
                <p id="text">
                  3 For U.S. employees, the entity shall categorize the employees in accordance with the Equal Employment Opportunity
                  Commission’s Employer Information EEO-1 report (EEO-1 Survey) Instruction Booklet, where each employee category
                  for disclosure is defined by corresponding job categories and descriptions in the Instruction Booklet:
                </p>
                <p id="text" className="indent">
                  3.1 Management includes the following:
                </p>
                <p id="text" className="doubleIndent">
                  3.1.1 Executives/Senior Level Officials and Managers: individuals who plan, direct and formulate policies, set
                  strategy and provide the overall direction of enterprises/organizations for the development and delivery
                  of products or services, within the parameters approved by boards of directors or other governing
                  bodies. Residing in the highest levels of organizations, these executives plan, direct or coordinate
                  activities with the support of subordinate executives and staff managers. They include, in larger
                  organizations, those individuals within two reporting levels of the CEO, whose responsibilities require
                  frequent interaction with the CEO. Examples of these kinds of managers are: chief executive officers,
                  chief operating officers, chief financial officers, line of business heads, presidents or executive vice presidents of functional areas or operating groups, chief information officers, chief human resources
                  officers, chief marketing officers, chief legal officers, management directors and managing partners.
                </p>
                <p id="text" className="doubleIndent">
                  3.1.2 Non-executive management includes First/Mid Level Officials and Managers: individuals who serve as
                  managers, other than those who serve as Executive/Senior Level Officials and Managers, including
                  those who oversee and direct the delivery of products, services or functions at group, regional or
                  divisional levels of organizations. These managers receive directions from the Executive/Senior Level
                  management and typically lead major business units. They implement policies, programs and directives
                  of executive/senior management through subordinate managers and within the parameters set by
                  Executive/Senior Level management. Examples of these kinds of managers are: vice presidents and
                  directors, group, regional or divisional controllers; treasurers; human resources, information systems,
                  marketing, and operations managers. The First/Mid Level Officials and Managers subcategory also
                  includes those who report directly to middle managers. These individuals serve at functional, line of
                  business segment or branch levels and are responsible for directing and executing the day-to-day
                  operational objectives of enterprises/organizations, conveying the directions of higher level officials and
                  managers to subordinate personnel and, in some instances, directly supervising the activities of exempt
                  and non-exempt personnel. The EEO-1 Job Classification Guide provides examples of job titles in this category.
                </p>
                <p id="text" className="indent">
                  3.2 Professionals is defined as the following: most jobs in this category require bachelor and graduate degrees,
                  and/or professional certification. In some instances, comparable experience may establish a person’s
                  qualifications. The EEO-1 Job Classification Guide provides examples of job titles in this category.
                </p>
                <p id="text" className="indent">
                  3.3 All other employees includes those employees who are not classified as management or professionals.
                </p>
                <p id="text">
                  4 For non-U.S. employees, the entity shall categorize the employees in a manner generally consistent with the
                  definitions provided above, though in accordance with, and further facilitated by, any applicable local regulations,
                  guidance, or generally accepted definitions.
                </p>
                <p id="text">
                  5 The entity shall categorize the gender of its employees as female, male, or not disclosed/available.
                </p>
                <p id="text">
                  6 The entity shall classify the racial/ethnic group of its U.S. employees in accordance with the EEO-1 Survey Instruction
                  Booklet and use the following categories: Asian, Black or African American, Hispanic or Latino, White, Other (which
                  includes Native American or Alaska Native, Native Hawaiian or Pacific Islander, and “Two or More Races”
                  classifications), or not disclosed/available.
                </p>
                <p id="text">
                  7 The entity may provide supplemental disclosures on gender and/or racial/ethnic group representation by country or region.
                </p>
                <p id="text">
                  8 The entity may provide supplemental contextual disclosures on factors that significantly influence gender and/or
                  racial/ethnic group representation, such as the country or region where employees are located.
                </p>
                <p id="text">
                  9 The entity may disclose gender and/or racial/ethnic group representation by employee category in the following table formats:
                </p>
                <img class="img-fluid" className="indent" src={imgSrc} />
              </div>
            </div>
          </div>
          <h6 style={{marginTop: "1.5%"}}>Please Enter Answers Below:</h6>
          <textarea id="textInput" class="form-control" rows="5" />
          <div id="submitBtnDiv" >
            <button id="submitBtn" class="btn" data-bs-toggle="modal" data-bs-target="#popupModal">Submit</button>
          </div>
          <div class="modal fade" id="popupModal">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Thanks for Submitting</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
