import React from "react";

export const NavbarData = [
  {
    title: "Dashboard",
    icon: <i class="bi bi-grid-1x2-fill" />,
    link: "dashboard",
  },
  {
    title: "Reporting",
    icon: <i class="bi bi-pie-chart-fill" />,
    link: "reporting",
  },
  {
    title: "SDG",
    icon: <i class="bi bi-disc-fill" />,
    link: "sdg",
  },
  {
    title: "Tasks",
    icon: <i class="bi bi-clipboard2-check-fill" />,
    link: "tasks",
  },
  {
    title: "Environment",
    icon: <i class="bi bi-clouds-fill" />,
    link: "environment",
  },
  {
    title: "Logout",
    icon: <i class="bi bi-arrow-left-circle-fill" />,
    link: "log-out",
  },
]

